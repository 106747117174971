import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <p className="copyright">
            © {currentYear} Abraham Jeyaraj. All rights reserved.
          </p>
        </div>
        <div className="footer-section">
          <p className="terms">
            By using this site, you agree to our{' '}
            <Link to="/terms" className="footer-link">
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link to="/privacy" className="footer-link">
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;