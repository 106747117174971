import React from 'react';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import portfolioContent from '../content/portfolio.txt';


const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="paper"
      >
        <ReactMarkdown>{portfolioContent}</ReactMarkdown>
      </motion.div>
    </div>
  );
};

export default Portfolio;