import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import legalContent from '../content/legal.txt';

const Legal = () => {
  const { type } = useParams();
  
  // Split the content into Terms and Privacy sections
  const sections = legalContent.split('---');
  const termsContent = sections[0];
  const privacyContent = sections[1];

  // Determine which content to show based on the route parameter
  const content = type === 'privacy' ? privacyContent : termsContent;

  return (
    <div className="container">
      <div className="content-wrapper">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="paper"
        >
          <div style={{
              p: 4,
              borderRadius: 2,
              backgroundColor: '#fff',
              '& h1': {
                fontSize: '2rem',
                fontWeight: 600,
                mb: 3,
                color: '#1a1a1a'
              },
              '& h2': {
                fontSize: '1.5rem',
                fontWeight: 500,
                mt: 4,
                mb: 2,
                color: '#333'
              },
              '& p': {
                fontSize: '1rem',
                lineHeight: 1.6,
                color: '#444',
                mb: 2
              },
              '& ul': {
                pl: 3,
                mb: 2
              },
              '& li': {
                fontSize: '1rem',
                lineHeight: 1.6,
                color: '#444',
                mb: 1
              }
            }}
          >
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Legal;